import http from "../utils/https";
import { Company } from "../interfaces/index";

const DEVICES_ENDPOINT = "/api/v1/companies";

const list = async (): Promise<Company[]> => {
  return http.get<Company[]>(DEVICES_ENDPOINT).then((response) => response.data);
};
const create = async (name: string, timezone: string): Promise<Company> => {
  return http
    .post<Company>(DEVICES_ENDPOINT, {
      name,
      timezone,
    })
    .then((response) => response.data);
};

const update = async (id: string, name: string, timezone: string): Promise<Company> => {
  return http
    .put<Company>(`${DEVICES_ENDPOINT}/${id}`, {
      name,
      timezone,
    })
    .then((response) => response.data);
};

const remove = async (id: string): Promise<Company> => {
  return http.delete<Company>(`${DEVICES_ENDPOINT}/${id}`).then((response) => response.data);
};

export const Companies = {
  list,
  create,
  update,
  remove,
};
