export enum AlertingMethod {
  email = "Email",
  phone = "Phone",
  sms = "SMS"
}

export const readingUnits = [
  { value: "Amps", name: "Amps" },
  { value: "On/Off", name: "On/Off" },
  { value: "C", name: "Temperature" },
  { value: "%", name: "%" },
  { value: "int", name: "int" },
  { value: "double", name: "double" },
  { value: "Amps*10", name: "Amps*10" },
  { value: "V", name: "Volts" },
  { value: "Hz", name: "Frequency" },
  { value: "m/s", name: "Speed" },
  { value: "°", name: "Angle Degree" },
  { value: "Labeled", name: "Labeled" },
];

export interface User {
  id?: string; // comes from DB (?)
  user_id?: string; // comes from Auth0
  email?: string;
  role?: Role[];
  user_metadata: {
    hiddenWidgets?: SensorData[];
    company?: Company | null;
    role: Role[];
    alertingMethod?: AlertingMethod;
    phoneNumber?: string;
  };
  password?: string;
  connection?: string;
  name?: string;
  nickname?: string;
  picture?: string;
}

export interface UserAlertSetting {
  id: string | null;
  userId: string;
  alertingMethod: [AlertingMethod];
  alertType: [string] | null;
  weekDays: [string] | null;
  startTime: Date | null;
  endTime: Date | null;
  isEditing: boolean;
}

export interface Role {
  id: string;
  name: string;
  description: string;
}

export interface Company {
  id: string;
  name: string;
  timezone: string;
}

export interface ClientData {
  pairid: number;
  email: string;
  clientid: number;
}

export interface DashboardSensor {
  type: string;
  isactive: boolean;
  sensorid: number;
  sensorKey: string;
  datatype: string;
  sensorname: string;
  offlabel: string;
  onlabel: string;
  locationid: number;
  labelMappings: LabelMapping[] | null;
}

export interface SimpleSensor {
  type: "Sensor" | "Synthetic";
  id: number;
  name: string;
  key: string;
}

export interface SensorData {
  sensorid: number;
  uuid: string;
  companyid: number;
  sensorname: string;
  dataindex: number;
  isactive: boolean;
  datatype: string;
  onlabel: string;
  offlabel: string;
  lorasensorkey: string;
  locationid: number;
  locationname?: string;
  scalingMethodId: number | string;
  sensorTypeId: number | string | null;
  scaleParamA: number;
  scaleParamB: number;
  scaleParamC: number;
  scaleParamD: number;
  scaleParamE: number;
  scaleParamF: number;
  labelMappings: LabelMapping[] | null;
}

export interface LabelMapping {
  sequence: number;
  inputValue: number;
  outputValue: string;
}

export interface SyntheticSensor {
  id: number;
  name: string;
  company_id: number;
  reading_type: string;
  location_id: number;
  location_name: string;
  is_active: boolean;
  on_label: string;
  off_label: string;
  trigger_sensor_id: number;
  trigger_sensor_name: string;
  transformation_type: string;
  transformation_min_value_on: number;
  label_mappings: LabelMapping[] | null;
}

export interface SyntheticSensor {
  id: number;
  name: string;
  company_id: number;
  reading_type: string;
  location_id: number;
  location_name: string;
  is_active: boolean;
  on_label: string;
  off_label: string;
  trigger_sensor_id: number;
  trigger_sensor_name: string;
  transformation_type: string;
  transformation_min_value_on: number;
}

export interface ScalingMethodConfig {
  id: number;
  name: string;
  parameters: ScalingMethodParameter[] | null;
}

export interface ScalingMethodParameter {
  parameter: string;
  parameterName: string;
  parameterDefault: number;
}

export interface SensorType {
  id: number;
  name: string;
  readingUnit: string;
  scalingMethodId: number;
  scaleParamA: number;
  scaleParamB: number;
  scaleParamC: number;
  scaleParamD: number;
  scaleParamE: number;
  scaleParamF: number;
}


export interface Device {
  id: string;
  uuid: string;
  name: string;
  companyid: string;
  type: string;
  noDataCheckMins: number,
}

export interface DeviceStatus {
  id: number,
  company_id: number;
  name: string;
  status: string;
  lastReading: number;
}

export interface LocationDto {
  id: number;
  name: string;
  companyid: string;
}

export interface SensorHistoryTable {
  id: string;
  name: string;
  dataType: {
    id: string;
    dataType: string;
  };
  timestamp: string;
  sensorReading: number;
}

export interface AlertHistoryTable {
  id: string;
  company_id: number
  sensor_id: number | null;
  alert_config_id: number;
  alert_type: string;
  escalated: boolean;
  trigger_type: string;
  sensor_reading: number;
  start_timestamp: number;
  end_timestamp: number | null;
  message: string;
  users: string[] | [];
}

export interface TableAddAlerts {
  alertid: number | null;
  companyid: number;
  sendNotifications: boolean;
  sensorid: number;
  sensorType: "Sensor" | "Synthetic";
  warninglow: number | string | null;
  warninghigh: number | string | null;
  alarmlow: number | string | null;
  alarmhigh: number | string | null
  heartbeatmins: number | null;
  notificationdelay: number | null;
  escalationfromwarningtoalert: number | null;
  users: string[] | null;
}

export interface TableReadAlerts {
  alertid: number | null;
  companyid: number;
  sendNotifications: boolean;
  sensorid: number;
  sensorType: "Sensor" | "Synthetic";
  sensorName: string;
  warninglow: number | string | null;
  warninghigh: number | string | null;
  alarmlow: number | string | null;
  alarmhigh: number | string | null
  heartbeatmins: number | null;
  users: string[] | null;
}

export interface GuageOptions {
  min: number,
  max: number,
  alarmHigh: number | null,
  alarmLow: number | null,
  warningHigh: number | null,
  warningLow: number | null,
  chartColoring: ((number | string)[])[]
}

export class UserRoles {
  static readonly Admin = new UserRoles(
    "rol_XomisIlVrLfV6pqL",
    "admin",
    "Super admin, able to create any users, has all the permissions",
  );
  static readonly Installer = new UserRoles("rol_R83h2Tt197I3J6nQ", "Installer", "Can setup new devices ");
  static readonly Manager = new UserRoles(
    "rol_19qwXDF3YDzypCAV",
    "manager",
    "Manager, able to create the users withing the company",
  );
  static readonly Viewer = new UserRoles("rol_RyKxuQM9A3tKrFDj", "Viewer", "Can view data only ");

  private constructor(public readonly id: string, public readonly name: string, public readonly description: string) {
    return {
      id: id,
      name: name,
      description: description,
    } as Role;
  }
}

export interface HiddenSensor {
  deviceid: string;
  type: string;
}

export interface HiddenSensors {
  userid: string;
  hiddendevices: HiddenSensor[];
}

export interface AlertContact {
  alertcontactid?: number;
  name: string;
  clientid: number;
  email: string[];
  phone: string[];
}
