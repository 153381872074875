/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import { User } from "../interfaces";
import { Users } from "../services/users.service";
import { UserContext } from "./user.context";
import { useAuth0 } from "@auth0/auth0-react";

interface IClientContext {
  companyUsers: User[];
  fetchCompanyUsers: () => Promise<void>;
}

type Props = {
  children?: React.ReactNode;
};

const ClientContext = React.createContext({} as IClientContext);

const ClientContextProvider = ({ children }: Props) => {
  const [companyUsers, setCompanyUsers] = useState<User[]>([]);

  const { userWithRole, selectedClient } = useContext(UserContext);
  const { user } = useAuth0();

  const fetchUsers = async (): Promise<void> => {
    const response = await Users.list();

    let users = response;

    if (userWithRole?.user_metadata.company) {
      users = response.filter((user) => user.user_metadata.company?.id === userWithRole?.user_metadata.company?.id);
    }

    if (selectedClient) {
      users = response.filter((user) => user?.user_metadata?.company?.id === selectedClient.id);
    }

    setCompanyUsers(users);
  };

  useEffect(() => {
    if (user?.sub) {
      fetchUsers().catch((error) => console.error(error));
    }
  }, [selectedClient, user?.sub]);

  const state = {
    companyUsers,
    fetchCompanyUsers: fetchUsers,
  };

  return <ClientContext.Provider value={state}>{children}</ClientContext.Provider>;
};

export { ClientContextProvider, ClientContext };
