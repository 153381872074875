import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./config/theme";
import reportAccessibility from "./utils/reportAccessibility";
import { Auth0Provider } from "@auth0/auth0-react";
import { LoadingWrapper } from "./components/LoadingWrapper/LoadingWrapper";

import "./index.scss";
import { UserContextProvider } from "./contexts/user.context";
import { ClientContextProvider } from "./contexts/clients.context";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    authorizationParams={{
      // eslint-disable-next-line camelcase
      redirect_uri: window.location.origin,
      cacheLocation: "localstorage",
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
      scope: "profile email users",
    }}>
    <UserContextProvider>
      <ClientContextProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <LoadingWrapper>
              <App />
            </LoadingWrapper>
          </ThemeProvider>
        </BrowserRouter>
      </ClientContextProvider>
    </UserContextProvider>
  </Auth0Provider>,
  // {/* </React.StrictMode>, */}
);

reportAccessibility(React);
reportWebVitals(console.table);
