import CircularProgress from "@mui/material/CircularProgress";

import "./FullScreenLoader.scss";

interface IFullScreenLoader {
  height?: string | number | undefined;
}

export const FullScreenLoader = ({ height }: IFullScreenLoader): JSX.Element => (
  <div
    style={{ height: height }}
    className="loading-wrapper ">
    <CircularProgress
      role="progressbar"
      title="Loading"
    />
  </div>
);
