/* eslint-disable @typescript-eslint/no-explicit-any */
import { Role, UserRoles } from "../../interfaces";
import HomeIcon from "@mui/icons-material/Home";
import TriangleIcon from "@mui/icons-material/ChangeHistory"
import PersonIcon from "@mui/icons-material/Person";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import AnalyticsIcon from "@mui/icons-material/Analytics";

export const sidebarItems = (role: Role[]) => {
  const sidebarItems: any[] = [
    {
      id: 0,
      icon: <HomeIcon className="navigation__icon" />,
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      id: 1,
      icon: <TriangleIcon className="navigation__icon" />,
      name: "All Zones",
      path: "/dashboard-allzones",
    },
    {
      id: 2,
      icon: <PersonIcon className="navigation__icon" />,
      name: "Client Management",
      path: "/client-management",
    },
    {
      id: 3,
      icon: <ViewQuiltIcon className="navigation__icon" />,
      name: "Installer Menu",
      path: "/installer-menu",
    },
    {
      id: 4,
      icon: <NotificationImportantIcon className="navigation__icon" />,
      name: "Alert Management",
      path: "/alert-management",
    },
    {
      id: 5,
      icon: <AnalyticsIcon className="navigation__icon" />,
      name: "Report",
      path: "/report",
    },
  ];

  if (role[0].id === UserRoles.Admin.id || role[0].id === UserRoles.Manager.id) {
    return sidebarItems;
  }

  // if (role[0].id === UserRoles.Installer.id) {
  //   return sidebarItems.filter((item) => item.path !== "/alert-management");
  // }

  if (role[0].id === UserRoles.Viewer.id) {
    return sidebarItems.filter((item) => item.path !== "/client-management" && item.path !== "/installer-menu");
  }

  return sidebarItems;
};
